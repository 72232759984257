<template>
  <fullscreen-layout>
    <div class="container text-center">
      <div class="input-group mx-auto w-50">
        <div class="input-group-prepend">
          <span class="input-group-text">個案編號</span>
        </div>
        <input type="text" class="form-control" v-model="name" />
      </div>

      <div class="mx-auto mt-3">
        <div class="btn btn-lg btn-success m-3" @click="selectAge(1.5)">
          1.5歲題組
        </div>
        <div class="btn btn-lg btn-success m-3" @click="selectAge(2.5)">
          2.5歲題組
        </div>
      </div>
    </div>
  </fullscreen-layout>
</template>

<script>
import FullscreenLayout from '@/layout/FullscreenLayout'
import store from '@/store'
import router from '@/router'

export default {
  name: 'HomeView',
  components: {
    FullscreenLayout
  },
  data() {
    return {
      name: store.state.user.name
    }
  },
  methods: {
    selectAge(age) {
      store.commit('user/SET_USER', {
        name: this.name,
        age: age
      })

      store.dispatch('questions/init', age).then(function () {
        router.push({ name: 'questions' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.fullscreen-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
