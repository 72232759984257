<template>
  <fullscreen-layout>
    <div class="container text-center">
      完成
      <router-link :to="{ name: 'home' }">回到首頁</router-link>
    </div>
  </fullscreen-layout>
</template>

<script>
import FullscreenLayout from '@/layout/FullscreenLayout'

export default {
  name: 'FinishedView',
  components: {
    FullscreenLayout
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.fullscreen-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
