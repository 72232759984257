export default {
  namespaced: true,
  state: {
    age: null,
    name: ''
  },
  getters: {},
  mutations: {
    SET_USER(state, user) {
      state.age = user.age
      state.name = user.name
    }
  },
  actions: {}
}
