<template>
  <div id="app">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'

export default {
  components: {},
  created() {
    if (router.currentRoute.name !== 'home' && !store.state.user.name) {
      router.push({ name: 'home' })
    }

    window.addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault()
      },
      false
    )
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
