import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    //問題清單
    pool: [],
    //目前選取題目
    selected: -1
  },
  getters: {},
  mutations: {
    save: function (state, pool) {
      state.pool = pool
    },
    updateSelected: function (state, nth) {
      state.selected = nth
    }
  },
  actions: {
    init(ctx, age) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/questions?age=' + age)
          .then((response) => {
            if (response.data.result) {
              ctx.commit('save', response.data.questions)
              ctx.commit('updateSelected', 0)

              resolve(response)
              return
            }
          })
          .catch((error) => reject(error))
      })
    },
    reply(ctx, params) {
      const formData = new FormData()

      if (typeof params === 'object') {
        Object.keys(params).forEach((key) => {
          formData.append(key, params[key])
        })
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/api/questions/reply', formData)
          .then((response) => {
            if (response.data.result) {
              resolve(response)
              return
            }
          })
          .catch((error) => reject(error))
      })
    },
    prev(ctx) {
      return new Promise((resolve) => {
        if (ctx.state.selected === 0) {
          resolve(false)
          return
        }
        ctx.commit('updateSelected', ctx.state.selected - 1)

        resolve(true)
      })
    },
    next(ctx) {
      return new Promise((resolve) => {
        if (typeof ctx.state.pool[ctx.state.selected + 1] === 'undefined') {
          resolve(false)
          return
        }
        ctx.commit('updateSelected', ctx.state.selected + 1)

        resolve(true)
      })
    }
  }
}
