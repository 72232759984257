<template>
  <div class="fullscreen-layout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FullscreenLayout',
  components: {}
}
</script>
<style lang="scss" scoped>
.fullscreen-layout {
  width: 100%;
  height: 100%;
}
</style>
